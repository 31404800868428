<template>
  <v-container grid-list-md class="general">
    <PageTitle :title="'Settings'" />
    <v-layout v-if="!isLoading" row wrap>
      <v-flex xs12>
        <v-text-field
          v-model="model.key"
          :error-messages="errors.collect('key')"
          v-validate.disable="'required'"
          data-vv-name="key"
          :data-vv-as="$tc('key', 2)"
          :label="$tc('key', 1)"
        ></v-text-field>
      </v-flex>
      <v-flex
        xs12
        v-if="model.key === 'global.terms_and_conditions'"
        class="mb-4"
      >
        <QuillEditor v-model="model.value" :config="editorConfig" />
      </v-flex>
      <v-flex xs12 v-if="model.key !== 'global.terms_and_conditions'">
        <v-text-field
          v-model="model.value"
          :label="$tc('value', 2)"
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="model.read_roles"
          :label="$tc('read-roles', 2)"
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="model.write_roles"
          :label="$tc('write-roles', 2)"
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-btn v-if="!settingId" @click="createSettings" class="blue-button">
          {{ $t("create") }}
        </v-btn>
        <v-btn v-if="settingId" @click="updateSettings" class="blue-button">
          {{ $t("save") }}
        </v-btn>
        <v-btn v-if="settingId" @click="deleteSettings" class="blue-button">
          {{ $t("delete") }}
        </v-btn>
        <v-btn
          :to="{ name: 'settings' }"
          class="simple-blueborder-button"
          >{{ $t("cancel") }}</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    model: {},
  }),
  computed: {
    settingId() {
      return this.$route.params.setting_id;
    },
    editorConfig() {
      return {
        label: "Value",
        style: {
          height: "600px",
        },
      };
    },
  },
  methods: {
    redirectToSettings() {
      this.$router
        .push({
          name: "settings",
        })
        .catch(() => {});
    },
    async createSettings() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      this.$api.settings
        .create(this.model)
        .then((response) => {
          this.$store.dispatch("addNotification", {
            message: this.$t("setting-created"),
          });

          this.$router
            .push({
              name: "settings_edit",
              params: {
                setting_id: response.data.data.id,
              },
            })
            .catch(() => {});
        })
        .catch((error) => this.errorMessageShow(error));
    },
    showSetting(id) {
      if (!id) return;

      this.isLoading = true;

      this.$api.settings
        .get(id)
        .then((response) => {
          this.isLoading = false;

          this.model = response.data.data;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async updateSettings() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      const specs = [this.model.id, this.model];

      this.$api.settings.update(...specs).then((response) => {
        this.$store.dispatch("addNotification", {
          message: this.$t("setting-updated"),
        });
      })
      .catch((error) => this.errorMessageShow(error));
    },
    async deleteSettings() {
      if (!this.model.id) return;

      const isConfirmed = await this.$vuetifyConfirmDialog.open(
        this.$tc("confirmation", 1),
        this.$t("setting-deletion-warning"),
        this.$t("cancel"),
        this.$t("proceed"),
      );

      if (!isConfirmed) return;

      this.$api.settings
        .delete(this.model.id)
        .then((response) => {
          this.$store.dispatch("addNotification", {
            message: this.$t("setting-deleted"),
          });

          this.redirectToSettings();
        })
        .catch((error) => this.errorMessageShow(error));
    },
  },
  watch: {
    settingId: {
      immediate: true,
      handler(val) {
        this.showSetting(val);
      },
    },
  },
};
</script>
